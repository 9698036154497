.ticket-card {
  margin: 8px 8px;
  background-color: white;
  display: flex;
  padding: 10px;
  font-size: 14px;
  border-radius: 20px;
  width: 18%;
  margin-left: 1.6%;
  border: 1px solid #ccc;
  align-items: center;
}

.ticket-card-left {
  width: 35%;
}

.ticket-card-left img {
  width: 75px;
  height: 75px;
  margin-top: 5px;
}

.ticket-card-right {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.ticket-card-right img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}


.cartLeft{
    width:75%;
    padding:20px 15px;
    max-height: 90vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-right: 2px solid #efefef;
}
.cartLeft::-webkit-scrollbar{
  display: none;
}
.cart-name{
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}

.cart-info{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 150px;
}
.cartLeft .cart-info .checked{
    display: flex;
    align-items: center;
    justify-self:center;
    padding: 0 30px 0 0;
}
.cart-message{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
}
.goods-logo{
    width: 80px;
    height: 100px;
}
.goods-text{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 10px;
    text-align: left;
}
.goods-text p{
    font-size: 15px;
}
.cart-num{
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart-num p{
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 5px;
    background-color: #efefef;
    font-size: 26px;
    cursor: pointer;
}
.cart-num input{
    width: 50px;
    height: 50px;
    text-align: center;

    border: none;
    outline: none;
    background-color: #efefef;
}
.operation{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.cart-price{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
}

.cartRigth{
  flex:1;
  padding: 20px;
  max-height: 90vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

}
.cartRigth::-webkit-scrollbar{
  display: none;
}
.orderTitle{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
}
.order-info{
    width: 100%;
    margin: 30px 0;
}
.order-info-choose-num{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
}
.choose-num-title{
    flex: 1;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}
.choose-num-option{
    font-size: 14px;
}
.start-calculation{
    width: 90%;
    margin: 0 auto;
    background-color: #c31c32;
    color: white;
    font-size: 14px;
    padding: 5px 0;
    cursor: pointer;
}

.container2{
  margin-top: 55px;
}
.container{
  margin-top: 55px;
  width: 100%;
}
.container1{
  height: 28px;
  width: 100%;
}
.recommend{
  width: 70%;
  min-height: 87vh;
  margin: 0 auto;
  background-color: white;
}
.recommend-banner{
  width: 100%;
  height: 60vh;
  position: relative;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.recommend-banner .title{
  /* width: 300px; */
  font-size: 50px;
  position: absolute;
  top: 20%;
  left: 2%;
  font-weight: bold;
  color: white;
  
}
.recommend-banner .see-more{
  color: white;
  position: absolute;
  top: 40%;
  left: 2%;
  font-weight: bold;
  font-size: 30px;

}
.recommend-goods{
  width: 100%;
  margin: 10px 0 10px;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-evenly;
  align-items: center;
}
.goods{

  /* margin-top: 20px; */
  /* flex: 1; */
  /* margin: 20px 0.5% 0; */
}
.goods-img{
  width: 100%;
  height: 240px;
  overflow: hidden;
}
.goosd-name{
  width: 100%;
  font-size: 16px;
  text-align: left;
  height: 50px;
  overflow: hidden;
}
.goods-price{
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 20px;
}
.moeny{
  color: #c31c32;
  font-weight: 400;
}
.hyj{
  width: 35px ;
  height: 35px;
  /* margin-top: -20px; */
}
.invalid{
  /* margin-bottom: -15px; */
  color: #ccc;
  margin-left: 14px;
}
.seripheral-box{
  width: 100%;
}
.seripheral-top{
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seripheral-top .top-img{
  flex: 1;
  height: 100%;
}

.seripheral{
  width: 100%;
  display: flex;
  background-color: white;
}
.left-selection{
  /* width: 15%; */
  margin-right:5%;
  display: flex;
  justify-content: center;
}
.selection-ul{
  /* width: 30%; */
  display: flex;
  list-style: none;
  margin-top: 40px;
  margin-right: 0;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 18px;
}
.selection-ul .selection-li{
  min-height: 80px;
  line-height: 80px;
  text-align: left;
  border-bottom: 2px solid #efefef;
  cursor: pointer;
}
.selection-ul .selection-li-choose{
  font-weight: 600;
  font-size: 26px;
  cursor: pointer;
}
.selection-ul .selection-li:hover{
  font-weight: 600;
  font-size: 26px;
  cursor: pointer;
}

.right-goods-list{
  flex: 1;
  padding-left: 20px;
  padding-top: 20px;
  border-left: 1px solid #efefef;
}
.right-goods-list .title{
  text-align: left;
}
.seripheral .recommend-goods .zb-goods{
    min-width: 23.25%;
    max-width: 23.25%;
    margin-left: 1%;
    margin-top: 20px;
    cursor: pointer;
}
.goods-pagination{
  width: 100%;
  margin: 20px 0;
  text-align: right;
}
.sale-box {
  background-color: #fff;
}
.sale-title {
  padding: 18px 23px;
  height: 85px;
  color: #fff;
  background-color: #b9060c;
}
.sale-title p {
  font-size: 12px;
}
.sale-list {
  padding: 18px 23px;
  border-bottom: 2px solid #f2f2f2;
}
.sale-list p {
  font-size: 12px;
  color: #888888;
  margin-bottom: 4px;
}
.sale-btn {
  margin-top: 19px;
  margin-right: 20px;
  display: inline-block;
  width: 24.155vw;
  height: 7.246vw;
  text-align: center;
  line-height: 7vw;
  color: #c31c32;
  border: 1px solid #c31c32;
  border-radius: 3.623vw;
}
.sale-shops-list {
  padding: 15px;
  border-top: 7px solid #f2f2f2;
  border-bottom: 5px solid #f2f2f2;
}
.sale-shops-list img {
  display: inline-block;
  width: 20.531vw;
  height: 20.531vw;
  border-radius: 2.415vw;
  object-fit: cover;
}
.sale-shops-title {
  display: inline-block;
  width: 76%;
  vertical-align: top;
  margin-left: 5px;
}
.sale-shops-title p:nth-child(1) {
  font-size: 16px;
  height: 61px;
  overflow: hidden;
}
.sale-shops-title p:nth-child(2) {
  font-size: 12px;
  color: #888888;
}
.sale-money {
  padding: 18px 23px;
  font-size: 12px;
  color: #888888;
}
.sale-money p {
  margin-bottom: 4px;
}
.calc-btn .am-button {
  width: 100px !important;
  height: 30px !important;
}

.sale-btn-canlc {
  margin-top: 19px;
  margin-right: 20px;
  display: inline-block;
  width: 95px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #888888;
  border: 1px solid #888888;
  border-radius: 20px ;
  cursor: pointer;
}
.ant-steps-vertical > .ant-steps-item{
  overflow: hidden !important;
}
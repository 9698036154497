.new-exhibition{
  width: 100%;
  height: 50vh;
  position: relative;
  cursor: pointer;
}
.exhibition-img{
  width: 90%;
  height: 80%;
  margin: 0 auto;
}

.bottom-info{
  width: 100%;
  height: 5%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-info .info-box{
  display: flex;
  justify-content: center;
  align-items: center;

}
.bottom-info .info-box .info-left{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.bottom-info .info-box .info-left .left-name{
  display: block;
  width: 8px;
  height: 8px;
  background-color: yellow;
  margin: 0 5px;
}
.bottom-info .info-box .info-righ{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0 20px;
}
.bottom-info .info-box .info-righ .time-img{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

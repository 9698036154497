.new-top_title{
  width: 100%;
  height: 48px;
  margin: 0 auto;
  position: fixed;
  top: 70px;
  left: 0;
  z-index:99;
  background-color: yellowgreen;
  --drop-down-color:rgb(250, 172, 3);
}
.new-top_title .new-ul{
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: left;
  align-items: center;
  list-style: none;
}
.new-top_title .new-ul .new-li {
  width: 10%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  color: white;
  margin-right: 5px;
  border-bottom:3px solid ;
  box-sizing: border-box
}
.new-top_title .new-ul .new-li:hover{
  cursor: pointer;
  border-bottom:3px solid var(--drop-down-color);
}
.new-top_title .new-ul .active{
  border-bottom:3px solid var(--drop-down-color);
}

@media (min-width:840px) {
  .new-top_title{
    top: 90px;
  }
}

@media (max-width:841px) {
  .new-top_title{
    top: 123px;
  }
}
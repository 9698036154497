.top {
  display: flex;
  justify-content: space-around;
  line-height: 50px;
  height: 110px;
  background-color: white;
  margin-top: 2px;
  padding: 5px;
}

.row {
  display: flex;
  text-align: center;
}

.group-btn {
  background-color: #c32d32;
  margin: 20px auto;
  color: white;
}
.longtxt {
  word-break: break-word;
  font-size: 14px;
  padding: 15px 20px;
  margin: 5px 0;
  background-color: #fff;
}
.triangle {
  width: 0px;
  height: 0px;
  border: 6px solid #ccc;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-top: 7px;
}
input{
  height: 34px;
}
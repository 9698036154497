@media (max-width:1600px) {
  .goods-one {
    width: 32%;
  }

  .new-one {
    width: 32%;
  }
}

@media (max-width:900px) {
  .goods-one {
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }

  .new-one {
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }
}

@media (max-width:500px) {
  .newBox {
    display: block;
  }

  .goods-one {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }

  .newBox {
    display: block;
  }

  .new-one {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}

.activeBox1 {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-top: 0.2rem solid #efefef;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  position: relative;
}

.active-img-left {
  width: 30%;
  height: 200px;
}

.active-content-right {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 200px;
  padding-left: 10px;
  position: relative;
}

.active-content-title {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  text-overflow: ellipsis;
}

.active-content-state {
  font-size: 14px;
  width: 100%;
}

.active-content-state span:nth-child(1) {
  color: blue;
}

.active-content-state span:nth-child(2) {
  margin-left: 5%;
  color: #919191;
}

.active-content-type {
  color: #c31c32;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.active-content-type .active-content-type-span {
  display: block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  background-color: #c31c32;
}

.active-content-bottom {
  width: 100%;
  text-align: right;
}

.active-content-bottom-browse {
  display: flex;
  justify-content: center;
  align-items: center;
}

.browse-left {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  font-size: 12px;
}

.browse-left img {
  width: 20px;
  height: 20px;
}

.browse-left span {}

.browse-right {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  font-size: 12px;
}

.browse-right img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.browse-right span {}

/*---------------------------商品-----------------------------*/
.goodsBox1 {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 10px 20px;
  /* border-top: 0.2rem solid #efefef; */
  margin: 0 auto;
}

.goods-one {
  width: 22.5%;
  margin-left: 2%;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  cursor: pointer;
}

.goods-image {
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.goods-title {
  width: 100%;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gods-price {

  font-size: 16px;
  text-align: left;
  color: #c31c32;
}

/*---------------------------新闻-----------------------------*/
.newBox1 {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 10px 20px;
  /* border-top: 0.2rem solid #efefef; */
  margin: 0 auto;
}

.new-one {
  width: 22.5%;
  margin-left: 2%;
  margin-top: 20px;
  margin-bottom: 10px;
  border: 0.1rem solid #efefef;
  text-align: left;
  cursor: pointer;
}

.new-image {
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.new-title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 10px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new_bottom {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.bottom-left {
  flex: 1;
  font-size: 14px;
  color: #c31c32;
  display: flex;
  justify-content: left;
  align-items: center;
}

.bottom-left span:nth-child(1) {
  width: 10px;
  height: 10px;
  display: block;
  background-color: #c31c32;
  margin-right: 10px;
}

.bottom-right {
  display: flex;
}

.bottom-right img {
  width: 20px;
  height: 20px;
  font-size: 13px;
  color: #d9d9d9;
  margin-right: 5px;
}

.bottom-right span {
  padding-right: 5px;
}
.apply-title {
  margin: 0 auto;
  margin-top: 5px;
  padding: 15px 15px 0 15px;
  align-items: center;
  width: 98%;
  height: auto;
  border-radius: 20px;
  background-color: white;
}
.apply-logistics {
  margin-top: 6px;
  height: 128px;
  border-radius: 20px;
  font-size: 16px;
  background-color: #fff;
}
.apply-logistics img {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 16px;
}
.refund {
  height: 63px;
  line-height: 63px;
  padding: 0 25px;
  border-bottom: 1px solid #dddada;
}
.refund-tui {
  height: 63px;
  line-height: 63px;
  padding: 0 25px;
}
.refund-details {
  display: none;
  margin-top: 6px;
  background-color: #fff;
  height: calc(100%);
}
.refund-details p {
  padding: 22px 15px 0 15px;
}
.refund-details input {
  border: none;
  background-color: #ffffff;
}
.refund-details p:nth-child(2) {
  margin-bottom: 8px;
  color: #918c8c;
}
.voucher {
  border-top: 2px solid #f2f2f2;
}
.voucher-img {
  padding: 0 15px 20px 15px;
}
.start-img {
  width: 70px;
  height: 73px;
  /*position: relative;*/
  /*top: -102px;*/
  /*left: 0;*/
}
.after-img {
  width: 70px;
  height: 73px;
}
.Submission {
  width: 93%;
  background-color: #c31c32;
}
.apply-img {
  display: inline-block;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  object-fit: cover;
}
.Order-box {
  display: inline-block;
  width: calc(75%);
  vertical-align: top;
}
.tickets-title {
  display: inline-block;
  margin-left: 13px;
  vertical-align: 0px;
  font-size: 16px;
}
.Order-box .goods-title {
  position: relative;
  top: 0px;
  left: 13px;
  color: #888888;
}

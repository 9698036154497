.wallet-box {
  height: 68px;
  border-top: 7px solid #fafafa;
  background-color: white;
}
.ball-list {
  border-top: 7px solid #fafafa;
}
.ball-list span {
  vertical-align: 4px;
  padding-left: 13px;
}
.ball-num {
  font-size: 20px;
  color: #ffffff;
}

.goods-footer {
  height: 45px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  border-radius: 8px 8px 0 0;
}

.goods-footer-cart,
.goods-footer-buy {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.goods-footer-cart {
  flex: 1;
  color: #0f223e;
  background-color: white;
  cursor: pointer;
}

.goods-footer-buy {
  flex: 1;
  color: white;
  background-color: #c31c32;
}
.shopping-details {
  width: 100%;
  z-index: 999;
  background-color: #f2f2f2;
  padding: 10px;
}
.shop-ok {
  height: 43px;
  background: #c31c32;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 43px;
  z-index: 99;
  cursor: pointer;
}
.purchase-box {
  float: right;
  margin-right: 8px;
}
.num-shop {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 3px;
  text-align: center;
  line-height: 35px;
  vertical-align: bottom;
}
.purchase-num {
  min-height: 60px;
  background-color: white;
  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
}
.purchase-num1 {
  background-color: white;
  width: 100%;
  padding: 10px;
  display: flex;
  color: #333;
  justify-content: space-between;
  align-items: center;
}
.purchase-num button {
  background: #f5f5f3;
  height: 35px;
  width: 35px;
  line-height: 20px;
  border: none;
  font-size: 18px;
}
.purchase-num p {
  float: left;
  padding-top: 6px;
  color: #333;
}
.contentBox :global .am-modal-transparent .am-modal-content {
  background: none !important;
}
.contentBox :global .am-modal-transparent {
  width: 100% !important;
}
.contentBox :global .am-modal-close-x {
  background-image: url('../../../assets/icon/guanbi.svg') !important;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

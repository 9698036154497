.container {
  height: 100%;
  /* overflow: scroll; */
  /* background-color: #efefef; */
  /* background-image: url('../../../../assets/image/member_bg.png'); */
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}
.content {
  position: relative;
  display: flex;
  height: calc(100% - 45px);
  
}

.content-bg {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
}
.content-box {
  position: fixed;
  height: 28%;
  z-index: 2;
  width: calc(100%);
}
.remaining-day {
  width: 100%;
  text-align: center;
  color: #c31c32;
  font-size: 20px;
  font-weight: bold;
}
.rights-container {
  padding: 15px 20px 0 20px !important;
  position: relative;
  z-index: 1;
  height: calc(50% - 50px);
}
.rights-sub {
  border-radius: 5px;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.rights-item {
  width: 8%;
}

.button-func {
  background-color: #c31c32;
  /* background-image: url(../../../assets/image/tig-bg.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  color: white;
  border: 1px solid #eeee;
  border-radius: 50px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 15px 20px;
  position: fixed;
  bottom: 0px;
  left: 4%;
  z-index: 2;
  text-align: center;
}

.button-func h2 {
  display: inline;
}

.tig {
  color: #d9d9d9;
  margin-right: 80px;
}

.bar {
  width: 25px;
  height: 5px;
  background-color: #d27267;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0);
}

.right-bar {
  width: 25px;
  height: 5px;
  background-color: rgba(195, 45, 50, 1);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0);
}

.top {
  padding: 20px 20px 10px 20px;
}

.top p {
  color: white;
}

.top-func {
  display: flex;
  justify-content: space-between;
}

.top-func-title {
  color: #ffc816;
  font-size: 22px;
  font-weight: bold;
}

.top-func-btn {
  background-color: #ffc816;
  color: white;
  border-radius: 30px;
  width: 100px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.rights-detail-bottom {
  width: 80%;
  border-radius: 10px;
  padding: 13px 20px;
  background: white;
  margin: 20px 10%;
}
.my-space-carousel {
  /* padding: 16px; */
  padding-top: 15px;
  overflow: hidden;
}
.ljkt-item {
  width: 200px;
  border-radius: 25px;
}

.voucher{
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.bank-item {
  width: 18%;
  margin-left: 1.6%;
  background-color: white;
  position: relative;
  margin-top: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px;
}

.bank-middle {
  margin: 0 10px 15px 10px;
  color: #888888;
  font-size: 16px;
}

.btn {
  color: white;
  background-color: rgba(195, 28, 50, 1);
  position: fixed !important;
  width: 100%;
  bottom: 0;
  z-index: 1;
  font-size: 15px;
}

.lable {
  font-size: 14px;
  color: #101010;
}

.trash {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 20px;
}

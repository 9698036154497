
.detail-top-left {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  background-color: #f5f5f5;
}
.detail-top-left img {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  object-fit: cover;
  object-position: top;
}

.step-item {
  padding: 30px 20px;
  position: relative;
  width: 85%;
  margin: 0 auto;
  background-color: #fff;
}
.step-tail::after {
  content: '';
  display: inline-block;
  background: #ddd;
  height: calc(100% - 5px);
  width: 2px;
  position: absolute;
  top: 60px;
  left: 34px;
}
.step-item:last-child .step-tail {
  display: none;
}
.step-title {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 16px;
  /* margin: 20px 0; */
}
.step-title_old {
  display: flex;
  justify-content: space-between;
  color: black;
  font-size: 16px;
}

.step-title-text {
  display: inline;
  margin: 0 10px;
}
.step-btn {
  float: right;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #c31c32;
  border-radius: 20px;
  text-align: center;
  color: #c31c32;
}
.step-icon {
  width: 30px;
  height: 30px;
}
.step-description {
  margin-left: 38px;
  margin-top: 3px;
}

.info-option{
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 10px 0;
  font-size:14px;
  
}
.signup-info .info-option .option-name{
  flex: 1;
  text-align: left;
  line-height: 30px;
}

.info-option1{
  margin: 10px 0;
  font-size:14px;
}
.info-option1 .option-name{
  text-align: left;
}
.option-content{
  color: #888888;
  font-size: 15px;
}
.bottom-btn {
  color: white;
  background-color: rgba(195, 28, 50, 1);
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}



.vote-content {
  padding: 10px;
}

.vote-item {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px; */
  border-bottom: 1px solid #ddd;
}

/* .item:last-child {
  border: unset;
} */

.item-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  /* border-bottom: 1px solid #ddd; */
}

.item img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.item-title {
  padding: 10px;
  flex: 1 1 auto;
}

.item-propgress {
  display: flex;
  justify-content: space-between;
  line-height: 10px;
}

.propgress-outer {
  background-color: #ddd;
  border-radius: 100px;
  margin-bottom: 10px;
  height: 10px;
  width: 70%;
}

.propgress-bar {
  width: 70%;
  height: 10px;
  background-color: #108ee9;
  transition: all 0.3s linear 0s;
  border-radius: 100px;
}
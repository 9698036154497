.container {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  height: 100%;
}
.container2{
  margin-top: 55px;
}
.content {
  flex: 1;
}

.topBar {
  position: sticky;
  top: 0;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.ruturnIcon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 45px;
  height: 100%;
  background-color: pink;
}
.title {
  font-size: 14px;
  color: #333333;
}

.order-item {
  width: 23%;
  margin-left: 1.6%;
  margin-top: 20px;
  background-color: white;
  border-radius: 20px;
  text-align: left;
  border: 1px solid #ccc;
}
.order-item-h {
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
}

.order-item-hs {
  color: #c31c32;
  font-size: 14px;
}
.order-item-hr {
  margin-left: auto;
}
.order-item-c {
  padding: 0 10px;
  /*display: flex;*/
  align-content: flex-start;
}
.order-item-cp {
  margin-right: 8px;
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  border: 0;
}

.order-item-cc {
  flex: 1;
  padding: 3px;
}
.order-item-ct {
  line-height: 22px;
  height: 65px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.order-item-cf {
  text-align: right;
  margin-bottom: 5px;
}
.bold {
  font-weight: bold;
}

.order-item-f {
  padding: 0 10px;
  height: 45px;
  line-height: 45px;
  text-align: right;
  border-top: 1px solid #e7e7e7;
}
.order-item-hf {
  padding: 0 10px;
  height: 12px;
  line-height: 12px;
  text-align: right;
}
.order-item-fs {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #c31c32;
  border: 1px solid #c31c32;
  border-radius: 15px;
}

.pane-content {
  height: 100%;
  overflow-y: auto;
}
.shop-img {
  display: block;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  object-fit: cover;
}
.shop-name {
  font-size: 16px;
  min-height: 50px;
}
.shop-size {
  font-size: 12px;
  color: #888888;
  height: 20px;
  display: block;
}
.shop-titles {
  /* line-height: 5.314vw; */
  /*height: 15.7vw;*/
  /* font-size: 3.865vw; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.list {
  width: 100%;
}




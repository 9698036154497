.diss-box {
  height: auto;
  border-top: 6px solid #f2f2f2;
}
.diss-box p {
  padding: 18px 24px;
}
.diss-list {
  padding: 0 24px 10px 24px;
  /*border-top: 5px solid #F2F2F2;*/
}
.diss-list p {
  padding: 0;
  font-size: 12px;
  color: #888888;
}
.user-img {
  width: 66px;
  height: 63px;
  margin-right: 8px;
  margin-top: 3px;
}

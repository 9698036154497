.container {
  height: 100%;
  background-color: #f5f5f5;
  overflow: auto;
}

.goods-col:not(:first-child) {
  border-top: 1px solid #e7e7e7;
}

.col-title {
  padding-top: 3px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.add-col {
  display: flex;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.address {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  margin-bottom: 30px;
  font-size: 13px;
}

.goodes {
  padding: 2px 15px;
  padding-top: 5px;
  text-align: left;
}

.tickets-details {
  margin: 0 auto;
  /* margin-top: 5px; */
  padding: 15px 15px;
  /*display: flex;*/
  align-items: center;
  width: 98%;
  height: auto;
  border-radius: 20px;
  background-color: white;
}

.tickets-pic {
  display: inline-block;
  width: 85px;
  height: 85px;
  border-radius: 10px;
  object-fit: cover;
}

.tickets-title {
  display: inline-block;
  margin-left: 13px;
  vertical-align: 23px;
  font-size: 16px;
}
.tickets-title-two {
  margin-left: 13px;
  /* display: inline-block; */
  vertical-align: 66px;
  font-size: 16px;
}

.cell:before {
  content: unset;
}

.card {
  padding: 8px 15px;
  margin-top: 5px;
  background-color: white;
}

.card-item {
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word;
}

.card-item-left {
  flex: 1;
}

.card-item-right {
  text-align: right;
}

.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: white;
}

.money {
  width: 100px;
  text-align: center;
  color: rgba(195, 28, 50, 1);
  font-size: 13px;
}

.time {
  text-align: left;
  flex: 1;
}

.payment {
  height: 100% !important;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding: 0 15px;
  border-radius: 0 !important;
  position: unset !important;
  background-color: rgba(195, 28, 50, 1);
}

.cell {
  /*padding: 0 15px;*/
  display: flex;
  height: 30px;
  align-items: center;
}

.cell-b {
  flex: 1;
}

.bg-red {
  background-color: rgba(195, 28, 50, 1);
}

.color-red {
  color: rgba(195, 28, 50, 1);
}

.address-row {
  display: flex;
  width: 100%;
  line-height: 25px;
}

.address-left {
  align-self: flex-start;
  min-width: 70px;
  text-align: left;
  font-size: 14px;
}

.address-right {
  text-align: left;
  line-height: 25px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-item-f {
  padding: 0 10px;
  height: 43px;
  line-height: 43px;
  text-align: right;
}
.order-item-fs {
  display: inline-block;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #c31c32;
  border: 1px solid #c31c32;
  border-radius: 15px;
}
.Order-box {
  display: inline-block;
  width: calc(75%);
}
.Order-box .goods-title {
  position: relative;
  top: -15px;
  left: 13px;
  color: #888888;
}

.step2-title {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  margin-top: 5px;
  padding: 15px 15px 0 15px;
  width: 98%;
  border-radius: 20px;
  background-color: white;
}

.step2-title-pic {
  display: block;
  margin-right: 15px;
  width: 85px;
  height: 85px;
  min-width: 85px;
  min-height: 85px;
  border-radius: 5px;
  /*background-color: color;*/
}

.step2-title-text {
  font-size: 16px;
  flex: 1;
  color: #333;
}
.gift-number {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.gift-number p {
  color: red;
}
.gift-number span {
  flex: 1;
  display: block;
  text-align: right;
}


*{
  padding: 0;
  margin: 0;
  
}
.shanjiao{
  width: 0px;
height: 0px;
border-left:5px solid transparent ;
border-right:5px solid transparent ;
border-top:5px solid transparent ;
border-bottom:5px solid #164284 ;
position: relative;
top: -10px;
margin: 0 auto;
}
.fontA{
  opacity: 0.5;
}
.container{
  width: 100%;
}
/* .titleList{
  --drop-downColor:rgb(250, 172, 3);
} */
.headerBox{
  width: 100%;
  padding: 5px 0;
  /* background-color: rgb(0, 1, 22); */
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  --drop-down-color:rgb(250, 172, 3);
}
.leftLogo{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 600;
  height: 100%;
  cursor: pointer;
  max-width: 20%;
}
.leftLogo .header-logo{
  width: 100%;
  height: 100%;
}
.titleList{
  flex: 1;
  font-size: 13px;
  height: 100%;
}
.titleList .one-ul{
  list-style: none;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
}
.titleList .one-ul .one-li{
  /* float: left; */
  flex: 1;
  /* min-width: 70px;
  width: 7%; */
  border-left: 1px solid #29283d;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
}
.titleList .one-ul .one-li:hover{
  cursor: pointer;
}
.titleList .one-ul .active{
  cursor: pointer;
  border-bottom: 3px solid var(--drop-downColor);
  box-sizing: border-box;
}
.rightVip{
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100%;
}
.rightVip img{
  width: 34;
  height: 34px;
}
.vip{
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100%;
  cursor: pointer;
  margin-left: 10px;
}
.vip-img{
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
.headerSmall{
  width: 100%;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgb(0, 1, 22);
}
.headerSmall .samllTop{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.headerSmall .samllTop .menu img{
  width: 30px;
  height: 30px;
}
.headerSmall .samllTop .title{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerSmall .samllTop .title p{
  font-size: 20px;
}
.headerSmall .samllTop .personal-center{
  font-size: 14px;
} 
.samllBottom{
  width: 100%;
  padding: 10px 0;
}
.samllBottom .one-ul{
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.samllBottom .one-ul .one-ul{
  border-left: 1px solid #919191;
}
.samllBottom .one-ul .one-li{
  float: left;
  flex:1;
  border-left: 1px solid #919191;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.samllBottom .one-ul .one-li:hover{
  border-bottom: 3px solid var(--drop-downColor);
  box-sizing: border-box;
}
.samllBottom .active{
  border-bottom: 3px solid var(--drop-downColor);
  box-sizing: border-box;
}
.portraitBar-auth-text {
  height: 25px;
  display: inline;
  line-height: 25px;
  padding: 0 10px;
  border: 1px solid #c5b683;
  border-radius: 0 50px 50px 0;
  border-left: unset;
  color: #c5b683;
  margin-left: -8px;
  font-size: 12px;
}
.item {
  background-color: white;
  height: 170px;
  padding: 5px 16px;
  position: relative;
  border-radius: 20px;
  margin: 5px 0;
}

.content {
  display: flex;
  position: relative;
  height: 100px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.item img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: top;
}

.middle {
  width: 75%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.time {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.btn-baoming {
  border: 1px solid;
  border-radius: 12px;
  width: 50px;
  text-align: center;
}

.status {
  width: 100px;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
  border-radius: 20px;
  text-align: center;
  display: inline-block;
}

.detail-btn {
  float: right;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #c31c32;
  border-radius: 20px;
  text-align: center;
  color: #c31c32;
}

.top-right {
  padding: 10px 6px 0 10px;
  font-size: 11px;
}

.info {
  padding: 20px;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: white;
  /* height: 300px; */
}

.detail-top-left {
  display: flex;
  padding: 10px 0 10px 10px;
  background-color: white;
}

.detail-top-left img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: top;
  padding-top: 3.415vw;
}

.option-main {
  padding: 20px;
  border: 1px dashed #000;
  margin: 20px;
  font-size: 16px;
}

.option-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.option-item a {
  width: 70px;
  height: 30px;
  color: white;
}

.btn {
  color: white;
  background-color: rgba(195, 28, 50, 1);
  position: fixed !important;
  width: 100%;
  bottom: 0;
  z-index: 1;
  font-size: 15px;
}

.quiz-item-detail {
  border-radius: 10px;
  /* box-shadow: 0px 1px 6px 0px #d0bfbf; */
  border: 1px solid white;
  margin: 10px 20px;
  padding: 5px;
  background-color: white;
}

.quiz-item-detail p {
  text-align: center;
}

.quiz-item:last-child {
  border-bottom: unset;
}

.quiz-item {
  padding: 20px 10px;
  border-bottom: 3px solid rgba(245, 245, 245, 100);
  background-color: white;
}

.quiz-item span {
  text-align: center;
  font-size: 14px;
}

.quiz-item p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.quiz-item img {
  align-self: center;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.quiz-info {
  flex-grow: 1;
}

.quiz-btn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #c31c32;
  border-radius: 20px;
  text-align: center;
  color: #c31c32;
}
.quiz-item-content .media-wrap.image-wrap{
  width: 100%;
}
.quiz-item-content .media-wrap.image-wrap img{
  width: 100%;
  object-fit: contain;
}
/*--------------------------------------荣誉列表------------------------------*/
.honr-box{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1% 0;
}
.img-one{
  width: 22.5%;
  /* height: 5.5rem; */
  margin-left: 2%;
  /* margin-top: 20px; */
}
@media (max-width:600px) {
  .honr-box{
    display: block;
  }
  .img-one{
    width: 90%;
    margin: 10px auto;
  }
}
.main-sponsor-box{
  height: 6.5rem;
  padding: 0 0.8rem;
  background-size: contain;
  background-repeat: repeat;
}
.main-sponsor-box .main-sponsor{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  /* background: linear-gradient(0deg,#181733 0,#181733 30%,rgba(173,16,40,.5)); */
}
.main-sponsor a{
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
  width: 100px;
  height: 44px;
}
.title{
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
  line-height: 3.6rem;
  padding-right: 2.4rem;
  letter-spacing: .1rem;
  border-right: 0.1rem solid hsla(0,0%,100%,.2);
  white-space: nowrap;
  font-weight: 400;
}
.logo-link{
  width: 7.2rem;
  height: 4rem;
  opacity: .6;
  margin-left: 2.8rem;
}
.col-12{
  width: 100%;
}
.matches-scroller{
  position: relative;
  padding: 3.4rem 3.4rem 2.4rem;
  background-color: #fff;
  overflow: hidden;
  /* border-bottom: 1px solid #efefef; */
  border-top: 0.2rem solid #efefef;
}
.matches-scroller:nth-child(1){
  border-radius: 1.2rem 1.2rem 0 0;
}
.teams-playlist {
  padding: 2rem 2.4rem 2.4rem;
  position: relative;
  background-color: #fff;
  border-top: 0.2rem solid #efefef;
}
.matches-scroller .widget_header{
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.matches-scroller .widget_title{
  text-align: left;
  flex: 0 0 auto;
  margin-right: 11.8rem;
  margin-bottom: 0;
  align-self: center;
  
  
}
.widget_title{
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}
.countdow{
  position: relative;
  display: flex;
  max-width: 52rem;
  height: 8rem;
  margin: 0 auto;
  padding: 1.2rem 1.6rem;
  border-radius: 0.5rem;
  /* background-image: url(../i/bg-elements/stripes-horizontal.png);
  background-size: cover;
  background-repeat: no-repeat; */
}
.countdown--header {
  background-image: none;
  padding: 0;
  flex: 1;
  margin: 0;
  height: auto;
}
.countdown_content{
  flex: 1;
  margin-right: 1.6rem;
}
.countdown--header .countdown__content {
  flex: 0;
  flex-basis: auto;
  align-self: center;
}
.countdown__content--no-sponsor {
  align-items: center;
  display: flex;
}
.countdown_title-container{
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  /* width: 100%; */
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.countdown--header .countdown__title-container {
  font-style: italic;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #222;
  margin-bottom: 0;
  line-height: 1.2rem;
}
.countdown_title{
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 0.3rem;
}
.countdown--header .countdown__title {
  font-weight: 400;
  white-space: nowrap;
}

.countdown {
  position: relative;
  display: flex;
  max-width: 52rem;
  height: 8rem;
  margin: 0 auto;
  padding: 1.2rem 1.6rem;
  border-radius: 0.5rem;
  /* background-image: url(../i/bg-elements/stripes-horizontal.png); */
  background-size: cover;
  background-repeat: no-repeat;
  /* color: #fff; */
}
.countdown__content--no-sponsor {
  align-items: center;
  display: flex;
}
.countdown__content {
  flex: 1;
  text-align: right;
}
.fixture-hero .countdown__title-container {
  align-items: flex-start;
}
.countdown__title-container {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-family: fcb-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.countdown__title {
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 0.3rem;
  font-size: 14px;
}
.countdown__clock1 {
  display: flex;
  flex: 1;
  align-items: center;
  /* color: #fff; */
}
.countdown__count {
    display: flex;
    align-items: center;
    font-size: 44px;
}
.countdown__clock1-item.is-zero .countdown__value1 {
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: bold;
}
.countdown__value1 {
  min-width: 5.2rem;
  line-height: 4.7rem;
  font-family: fcb-extra-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: bold;
  text-align: center;
}
.countdown__separator {
  display: inline-block;
  margin: 0 0.2rem;
  line-height: 4.8rem;
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.countdown__count-label1 {
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
  /* color: #fdc52c; */
  font-family: fcb-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
/*------------------------------header以下的内容样式----------------------------------*/
.wrapper {
  width: 100%;
  max-width: 95rem;
  margin: 0 auto;
  position: relative;
}
.matches-scroller__list-container {
  margin: 0 auto;
  position: relative;
}
.matches-scroller__list {
  white-space: nowrap;
  position: relative;
  transition: left .3s ease-out,-webkit-transform .3s ease-out;
  transition: left .3s ease-out,transform .3s ease-out;
  transition: left .3s ease-out,transform .3s ease-out,-webkit-transform .3s ease-out;
  will-change: left;
  word-spacing: 0.8rem;
  display: flex;
  flex-direction: row;
}
.matches-scroller-item {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem;
  background-color: #fff;
  border: 0.1rem solid #efefef;
  box-shadow: 0 0 0.8rem 0 rgb(0 0 0 / 6%);
  transition: box-shadow .3s ease;
}
.matches-scroller-item--small {
  min-width: 35.2rem;
}
.matches-scroller-item--link {
  background-image: url('');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border: none;
}
.matches-scroller__item-wrap--active {
  opacity: 1;
}
.matches-scroller-item--link .matches-scroller-item__link-element {
  text-decoration: none;
  display: block;
  height: 100%;
  background-image: linear-gradient(230deg,rgba(24,23,53,.9),rgba(154,0,11,.9));
}
.matches-scroller-item--link .matches-scroller-item__link-text-wrap {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.matches-scroller-item--link .matches-scroller-item__link-text, .matches-scroller-item--link .matches-scroller-item__link-text-bold {
  color: #fff;
  text-align: center;
  display: block;
  font-size: 3.2rem;
  text-decoration: none;
  line-height: 3.6rem;
}
.matches-scroller-item--link .matches-scroller-item__link-text {
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.matches-scroller-item--link .matches-scroller-item__link-text-bold {
  font-family: fcb-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.matches-scroller-item--link .matches-scroller-item__link-text, .matches-scroller-item--link .matches-scroller-item__link-text-bold {
  color: #fff;
  text-align: center;
  display: block;
  font-size: 3.2rem;
  text-decoration: none;
  line-height: 3.6rem;
}
.matches-scroller-item--primary .matches-scroller-item__header {
  background-color: #154284;
}
.matches-scroller-item__header {
  margin: -0.1rem;
  padding: 0.7rem 1.4rem 0.6rem;
  background-color: #181733;
  display: flex;
  color: #fff;
}
.matches-scroller-item__header-left {
  line-height: 1rem;
  word-spacing: 0.6rem;
  text-align: left;
}
.matches-scroller-item__header-center, .matches-scroller-item__header-left, .matches-scroller-item__header-right {
  flex: 1;
}
.matches-scroller-item__date-day {
  font-family: fcb-extra-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  line-height: 3.2rem;
  font-size: 4rem;
  display: inline-block;
}
.matches-scroller-item__date-info {
  text-transform: uppercase;
  display: inline-block;
  word-spacing: normal;
}
.matches-scroller-item__date-weekday {
  font-family: fcb-extra-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  display: block;
  font-size: 1.8rem;
  line-height: 1.6rem;
}
.matches-scroller-item__match {
  text-decoration: none;
}
.matches-scroller-match--small {
  padding: 1.6rem 2.7rem 1.1rem;
}
.matches-scroller-match {
  padding: 1.6rem 4rem 1.1rem;
  display: flex;
  position: relative;
}
.matches-scroller-match__arrow {
  position: absolute;
  right: 0.9rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.matches-scroller-match__arrow .icon {
  width: 1.2rem;
  height: 0.9rem;
  fill: #000;
}
.matches-scroller-match__arrow--mobile {
  display: none;
}
.matches-scroller-match__arrow {
  position: absolute;
  right: 0.9rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.matches-scroller-match--small .matches-scroller-match__team {
  padding-top: 4.3rem;
}
.matches-scroller-match__team {
  flex: 1;
  text-align: center;
  padding-top: 1rem;
}
.matches-scroller-match__badge {
  display: inline;
}
.visually-hidden, .visually-hidden-nav-item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  visibility: hidden;
}
.visually-hidden {
  width: 1px;
}
.icn-club-badge-50.icn-club-badge-50-ELC {
  background-position: -250px -100px;
  width: 50px;
  height: 50px;
  opacity: 1;
  display: inline-block;
}
.icn-club-badge-50, .icn-club-badge-50.icn-club-badge-50-default {
  background-position: 0 -450px;
  width: 50px;
  height: 50px;
  opacity: 1;
  display: inline-block;
}
.matches-scroller-match__badge.badge--mobile {
  display: none;
}
.matches-scroller-match__badge {
  display: inline;
}
.badge {
  display: none;
}
.matches-scroller-match--complete .matches-scroller-match__team {
  padding-top: 4.6rem;
}
.matches-scroller-match__team {
  flex: 1;
  text-align: center;
  padding-top: 1rem;
}
.matches-scroller-match__meta {
  word-spacing: normal;
}
.matches-scroller-match__competition {
  margin: 0 auto;
  width: 9.2rem;
  height: 2.4rem;
}
.matches-scroller-match__outcome {
  text-align: center;
}
.matches-scroller-match__outcome-text {
  font-family: fcb-regular,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  display: block;
  font-size: 1rem;
  color: #727272;
  position: relative;
  top: 0.8rem;
  min-height: 1rem;
}
.matches-scroller-match__outcome-value {
  line-height: 5.8rem;
  font-size: 4.8rem;
  display: inline-block;
  margin-bottom: 0.6rem;
}
.matches-scroller-match__score {
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  color: #154284;
  margin-bottom: 2rem;
}
.fixture-info__score-container, .fixture-info__team-container {
  display: flex;
  align-items: center;
}
.fixture-info__score-container {
  justify-content: center;
}
.fixture-info__score {
  height: 4rem;
  text-align: center;
}
.fixture-info__score>span {
  font-family: fcb-extra-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  display: inline-block;
  height: 3.9rem;
  font-size: 3.2rem;
  line-height: 3.6rem;
  text-align: center;
  color: #fff;
  background: linear-gradient(180deg,#2c4481 0,#181733);
  width: auto;
  min-width: 8rem;
  margin: 0 0.1rem;
  padding: 0 1rem;
}
.matches-scroller-match__team {
  flex: 1;
  text-align: center;
  padding-top: 1rem;
}
.matches-scroller-item__cta {
  word-spacing: 0.8rem;
  padding: 1.2rem 0.4rem;
  border-top: 0.1rem solid #efefef;
  font-size: 0;
  text-align: center;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 8.2rem;
}
.matches-scroller-item .button, .matches-scroller-item__button {
  word-spacing: normal;
  margin-bottom: 0.4rem;
}
.matches-scroller-item__button:nth-child(2), .matches-scroller-item__ticket:nth-child(2) {
  margin-left: 0.4rem;
}
.matches-scroller-item__text-desktop {
  display: inline;
}
.matches-scroller-item__text-mobile {
  display: none;
}
.matches-scroller-match--small .matches-scroller-match__badge svg {
  width: 5.5rem;
  height: 5.5rem;
}
.matches-scroller-match__badge svg {
  width: 8.6rem;
  height: 8.6rem;
}

.titleBox{
  width: 100%;
  position: relative;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 25px;
}
.titleBox .seeAll{
  font-size: 16px;
  color: #fff;
  width: 15%;
  text-align: center;
  padding:5px 0;
  position: absolute;
  top: -2px;
  right: 20px;
  background-color: #154284;
  line-height: normal;
}









@media (max-width:400px){
  .matches-scroller{
    overflow: visible;
  }
  .matches-scroller .widget_header{
    margin-bottom: 1rem;
    display: block;
  }
  .matches-scroller .widget_title{
    font-size: 1.8rem;
  }
  .countdown {
    max-width: none;
  }
  .matches-scroller__list {
    margin: 0 -0.4rem;
}
.badge--mobile {
  display: inline-block;
}
}
@media (max-width:480px){
.widget_title{
    font-size: 1.8rem;
  }
  .goods-one{
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}
@media (max-width:640px){
  .widget_title{
    margin-bottom: 1.2rem;
  }
  .matches-scroller .widget_header{
    display: block;
  }
  .countdown {
    padding: 0.8rem;
    height: 5rem;
  }
  .countdown__content {
    margin-right: 1.6rem;
    justify-content: flex-end;
    flex: auto;
  }
  .countdown__title-container {
    margin-bottom: 0.2rem;
    align-items: flex-end;
  }
  .countdown__title-container, .countdown__twitter-hashtag {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  .matches-scroller-match--large .matches-scroller-match__team, .matches-scroller-match--small .matches-scroller-match__team {
    padding-top: 4.3rem;
}
.matches-scroller-match--complete .matches-scroller-match__outcome-text {
  top: -0.2rem;
}
}


@media (max-width:750px){
  .matches-scroller{
    padding: 1.9rem 0 2.5rem;
  }
  .matches-scroller .widget_header{
    padding: 0;
    flex-wrap: wrap;
  }
  .matches-scroller .widget_title{
    flex: 1;
    text-align: center;
  }
  .matches-scroller .countdown {
    flex: 0 0 100%;
    margin: 0 auto;
    justify-content: center;
  } 
  .matches-scroller__list {
    word-spacing: 0.4rem;
}
}
@media (max-width:840px){
  .matches-scroller{
    margin: 0;
  }
  .widget_title{
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }
  .countdown {
    padding: 1rem;
    height: 6.5rem;
    max-width: 42rem;
  }
  .countdown__content {
    margin-right: 3rem;
  }
  .countdown__title-container, .countdown__twitter-hashtag {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
  .matches-scroller__list {
    margin: 0 -0.8rem;
  }
  .matches-scroller-item {
    margin: 0.2rem;
  }
  .matches-scroller-item--large, .matches-scroller-item--small {
    min-width: 28.8rem;
  }
  .matches-scroller-item__header {
    padding: 0.6rem 1.2rem 0.8rem;
  }
  .matches-scroller-item__date-day {
    vertical-align: bottom;
    font-size: 2.8rem;
  }
  .matches-scroller-item__date-weekday {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
  .matches-scroller-match {
    padding: 1rem 2.2rem 0.6rem;
  }
  .matches-scroller-match--small .matches-scroller-match__team {
    padding-top: 4.3rem;
}
.matches-scroller-match--large .matches-scroller-match__team, .matches-scroller-match--small .matches-scroller-match__team {
  padding-top: 4.3rem;
}
.matches-scroller-match__badge.badge--desktop {
  display: none;
}
.matches-scroller-match__badge.badge--mobile {
  display: inline;
}
.matches-scroller-match--small .matches-scroller-match__team {
  padding-top: 4.3rem;
}
.matches-scroller-match__competition {
  margin-bottom: 0.6rem;
}
.matches-scroller-match--complete .matches-scroller-match__outcome-text {
  top: 0;
}
.matches-scroller-match__outcome-text {
  font-size: .9rem;
}
.matches-scroller-match__outcome-value {
  line-height: 3.9rem;
  font-size: 3.2rem;
}
.matches-scroller-match__score {
  margin-bottom: 0.5rem;
}
.matches-scroller-item__cta {
  word-spacing: 0.2rem;
}
.matches-scroller-item__button {
  max-width: 13.4rem;
  min-width: auto;
  width: 50%;
}
.matches-scroller-item__button:nth-child(2), .matches-scroller-item__ticket:nth-child(2) {
  margin-left: 0.4rem;
}
.matches-scroller-item__text-desktop {
  display: none;
}
.matches-scroller-item__text-mobile {
  display: inline;
}

.titleBox .seeAll{
  font-size: 16px;
  color: #fff;
  width: 30%;
  text-align: center;
  padding:5px 0;
  background-color: #154284;
  line-height: normal;
  margin:0 auto;
  position: relative;
  top: 0;
  left: 0;
}
}
@media (max-width:950px){
  .matches-scroller{
    padding: 1.2rem 0 2rem;
    overflow: visible;
  }
  .matches-scroller .widget_header{
    padding-left: 2rem;
    margin-bottom: 1.6rem;
    text-align: center;
  }
  .matches-scroller__list--show {
    opacity: 1;
  }
  .matches-scroller__list {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0;
    margin: 0 -1.6rem;
    opacity: 0;
    transition: opacity .3s ease-out;
  }
}
@media (max-width: 1000px){
  .matches-scroller .countdown__title-container {
    font-size: 1.2rem;
    font-size: 1.2rem;
  }
  .countdown--header .countdown__title {
    display: none;
  }
}
@media (max-width:1230px){
  .matches-scroller .widget_header{
    text-align: left;
  }
  .matches-scroller .widget_title{
    margin-right: 3rem;
  }
  .matches-scroller .countdown {
    margin-left: 3rem;
  }
}
@media (max-width:1280px) {
  .matches-scroller-match__arrow {
    display: none;
}
.matches-scroller-match__arrow--mobile {
  display: block;
}
.matches-scroller-match--small .matches-scroller-match__team {
  padding-top: 5.3rem;
}
.matches-scroller-match--small .matches-scroller-match__badge.badge--desktop {
  display: none;
}
.matches-scroller-match--small .matches-scroller-match__badge.badge--mobile {
  display: inline;
}

}
@media (max-width: 1025px){
.badge--desktop {
    display: inline-block;
}
}


@media (max-width:1600px) {
  .goods-one{
    width: 32%;
  }

  .new-one{
    width: 32%;
  }
}
@media (max-width:900px) {
  .goods-one{
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }
  .new-one{
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }
}
@media (max-width:500px) {
  .newBox{
    display: block;
  }
  .goods-one{
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  .newBox{
    display: block;
  }
  .new-one{
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}













@media (max-width:1600px) {
  .goods-one{
    width: 32%;
  }

  .new-one{
    width: 32%;
  }
}
.activeBox{
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-top: 0.2rem solid #efefef;
  margin: 0 auto;
}
.active-img-left{
  width: 30%;
  height: 200px;
}
.active-content-right{
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 200px;
}
.active-content-state{
  font-size: 14px;
  width: 100%;
}
.active-content-state span:nth-child(1){
  color: blue;
}
.active-content-state span:nth-child(2){
  margin-left: 5%;
  color: #919191;
}
.active-content-type{
  color: #c31c32;
}

.active-content-type span{
  width: 10px;
  height: 10px;
  background-color: #c31c32;
}
.active-content-bottom{
  width: 100%;
  text-align: right;
}
.active-content-bottom-browse{
  display: flex;
  justify-content: center;
  align-items: center;
}
.browse-left{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  font-size: 12px;
}

.browse-left img{
  width: 20px;
  height: 20px;
}

.browse-left span{

}
.browse-right{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d9d9d9;
  font-size: 12px;
}

.browse-right img{
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.browse-right span{

}

/*---------------------------商品-----------------------------*/
.goodsBox{
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 10px 20px;
  border-top: 0.2rem solid #efefef;
  margin: 0 auto;
}
.goods-one{
  width: 22.5%;
  margin-left: 2%;
  margin-top:20px;
  margin-bottom:10px;
}
.goods-image{
  width: 100%;
  height: auto;
}
.goods-title{
  font-size: 16px;
  text-align: left;
}
.gods-price{

  font-size: 16px;
  text-align: left;
  color: #c31c32;
}
/*---------------------------新闻-----------------------------*/
.newBox{
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 10px 20px;
  border-top: 0.2rem solid #efefef;
  margin: 0 auto;
}
.new-one{
  width: 22.5%;
  margin-left: 2%;
  margin-top:20px;margin-bottom:10px;
  border: 0.1rem solid #efefef
}
.new-image{
  width: 100%;
  height: auto;
}
.new-title{
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 10px auto;
}
.new_bottom{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bottom-left{
  flex: 1;
  font-size: 14px;
  color: #c31c32;
  display: flex;
  justify-content: left;
  align-items: center;
}
.bottom-left span:nth-child(1){
  width: 10px;
  height: 10px;
  display: block;
  background-color: #c31c32;
  margin-right: 10px;
}
.bottom-right img{
  width: 20px;
  height: 20px;
  font-size: 13px;
  color: #d9d9d9;
}
.bottom-right span{
  padding-right: 5px;
}

@media (max-width:1600px) {
  .teams-playlist .goods-one{
    width: 32%;
    margin-left: 1%;
    padding: 0;
  }
  .teams-playlist .new-one{
    width: 32%;
    margin-left: 1%;
    padding: 0;
  }
  .img-one{ 
    width: 32%;
    margin-left: 1%;
  }
}

@media (max-width:900px) {
  .teams-playlist .goods-one{
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }
  .teams-playlist .new-one{
    width: 43%;
    margin-left: 1.3%;
    padding: 0;
  }
  .img-one{
    width: 43%;
    margin-left: 1.3%;
  }
}
@media (max-width:600px) {
  .teams-playlist .newBox{
    display: block;
  }
  .teams-playlist .goods-one{
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  .teams-playlist .newBox{
    display: block;
  }
  .teams-playlist .new-one{
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  .honr-box{
    display: block;
  }
  .img-one{
    width: 90%;
    margin: 10px auto;
  }
}
p{
  margin: 0;
}
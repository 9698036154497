.big > .ant-upload {
  width: 150px;
  height: 150px;
  position: relative;
}
.middle > .ant-upload {
  width: 100px;
  height: 100px;
  position: relative;
}
.small > .ant-upload {
  width: 50px;
  height: 50px;
  position: relative;
}

.delIcon{
  position: absolute;
  top: 0px;
  right: 0;
  margin: 5px;
}

.image-content img{
  width: 100%;
  /* max-height: 200px; */
}

.my-upload-box{
  /* justify-content: center;
  display: flex; */
}
.ant-upload.ant-upload-select-picture-card > .ant-upload{
  overflow: hidden !important;
}
.big-img-box {
  display: flex;
  height: 68vh;
}

.big-img-one {
  width: 33.333333333%;
  /* min-height: 100%; */
  transition: width 0.5s;
}
.big-img-one-title{
  width:100%;
  position:absolute;
  left:0;
  bottom:0;
  z-index:3;
  text-align:center;
  font-size:14px;
  font-weight:400;
  color: white;
}
.on-select {
  width: 90%;
  /*flex-shrink: 0;*/
}
.on-select .big-img-one-title{
  font-size: 50px;
  font-weight: bold;
  transform:translateY(-8px);
	/* margin-top: -8px; */
  transition:all 0.3s ease-in;
}
.un-select {
  /* width: 20%; */
}

.top-img{
  width: 100%;
  height: 95%;
  position: relative;
}
.big-img-one .bottom-info{
  height: 5%;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.info-box{
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-left{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.left span:nth-child(1){
  display: block;
  width: 8px;
  height: 8px;
  background-color: yellow;
  margin: 0 5px;
}
.info-righ{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 0 20px;
}
.time-img{
  width: 20px;
  height: 20px;
  margin: 0 5px;
}



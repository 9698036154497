aside {
    position: fixed;
    height: 93vh;
    z-index: 99;
    width: 200px;
    padding-bottom: 50px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    --menu-background-color: #001529;
    --menu-color: rgba(255, 255, 255, 0.65);
    background-color: var(--menu-background-color);
}

aside::-webkit-scrollbar {
    display: none;
}

.Vip-content {
    flex: 1;
    background-color: #fff;
}

.vip-top {
    width: 100%;
    padding: 30px 20px;
    display: flex;
    justify-content: left;
    align-content: center;
    border: 1px solid red;
}

.vip-top-left {
    height: 100%;
    display: flex;
    justify-content: center;
}

.pepole-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.vip-top-left p {
    font-size: 25px;
}

.vip-top-right {
    width: 50%;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vip-top-right-message {
    flex: 1;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
}

.vip-top-right-message p:nth-child(1) {
    color: blue;
}

.vip-cnter {
    width: 80%;
    padding: 10px 5px;
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    border: 1px solid #efefef;
}

.vip-center-left {
    width: 300px;
    height: 200px;
}

.vip-center-right {
    flex: 1;
    height: 200px;
    margin-left: 30px;
}

.vip-center-right-title {
    text-align: left;
    padding-bottom: 10px;
}

.vip-center-right-message {
    width: 90%;
    border-top: 1px solid #efefef;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.vip-center-right-message-info {
    flex: 1;
    display: flex;
    height: 120px;
    font-size: 16px;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    color: black;
}

.vip-center-right-message-info p:nth-child(1) {
    font-weight: 600;
}

.vip-bottom {
    width: 97%;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vip-bottom-one {
    border: 1px solid #efefef;
    width: 90%;
    margin: 0;
}

.vip-bottom-title {
    margin: 10px auto 0;
    width: 90%;
}

.vip-bottom-right-message {
    width: 90%;
    height: 120px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.vip-bottom-right-message-one {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;

}

.vip-bottom-right-message-one-img {
    width: 25px;
    height: 25px;
    margin: 10px 0;
}

.vip-bottom-right-message:nth-child(2) {
    flex-wrap: wrap;
    justify-content: left;
}

.vip-bottom-right-message-active {
    width: 47%;
    margin-left: 2%;
    display: flex;
}

.vip-bottom-right-message-active-img {
    width: 50px;
    height: 50px;
}

.vip-bottom-right-message-active-info {
    flex: 1;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.ant-menu-item a {
    color: var(--menu-color) !important;
}

/* 选中菜单 */
.ant-menu-inline .ant-menu-item-selected {
    background-color: var(--menu-background-color) !important;
    color: var(--menu-color) !important;
}

.ant-menu-inline .ant-menu-item-selected a {
    /* text-decoration: underline; */
    border-bottom: 1px solid var(--menu-color) !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: var(--menu-background-color) !important;
    color: var(--menu-color) !important;
}


.personal-container {
    margin-top: 35px;
    width: '100%';
    min-height: '100vh';
    display: 'flex';
    flex-direction: 'column';
}
.accountHeader{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0 20px 1.5%;
}
.accound-top{
  padding: 20px 0 20px 1.5%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.data-list{
  width: 80%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.data-list .list-item{
  width: 15%;
  margin-bottom: 20px;
  margin-left: 1.5%;
  border: 1px solid black;
  padding: 20px 0;
  min-width: 260px;
}
.data-list .list-item .list-title{
  width: 100%;
  font-size: 20px;
}
.data-list .list-item .list-info{
  width: 100%;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ccc;
}

.real-name-face,
.real-name-image {
  display: block;
  border: 1px solid white;
  /* height: 220px; */
  width: 80%;
  margin: 10px auto;
  border-radius: 10px;
}

.real-name-title {
  padding: 10px;
}

.real-name-face {
  height: auto;
}

/*--------------------------------------赞助商列表------------------------------*/
.sponsorAll{
  width: 100%;
  padding: 20px 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.orgName{
  width: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: white;
}
.orgName span{
  padding:3px 40px ;
  background-color: #000;
}
.sponsor-one{
  width: 95%;
  margin: 0 auto;
  text-align: left;
}
.sponsor-title{
  font-size: 16px;
  color: rgba(255, 217, 0, 0.815);
  background-color: #000;
}
.sponsor-img-list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  margin: 5px 0;
  border-left: 2px solid rgba(255, 217, 0, 0.815);
}
.sponsor-img-list .sponsor-img{
  /* width: 12%; */
  margin-left: 1.3%;
  /* height: 8rem; */
}
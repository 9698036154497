.collection {
  min-height: 100vh;
  text-align: left;
  background-color: black;
}

.content {
  padding-bottom: 45px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: flex-start; */
}

.collection-item {
  width: inherit;
  /* height: 380px; */
  margin-left: 1.428%;
  margin-top: 20px;
  border-radius: 10px;
  width: 23%;
  padding-bottom: 10px;
}

.box {
  position: relative;
  height: 14vw;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #b7a8567d;
  border-radius: 10px;
}

.backgroud {
  width: 100%;
  height: 14vw;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  filter: blur(5px);
}

.img-frame {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: calc(20%);
  left: 0;
  overflow: hidden;
}

.img-main {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.name {
  color: white;
  padding: 0 10px;
  margin-top: 5px;
}

.gold-btn {
  background-color: #f3e0b9;
  color: black;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  border-radius: 5px;
}









.nft-content {
  height: 100%;
  width: 100%;
  background-color: black;
  overflow-y: auto;
}

.nft-image {
  width: 80%;
  height: auto;
  object-fit: contain;
  margin: 30px 0;
}

.nft-top {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  text-align: center;
  /* height: 525px; */
}

.info-content {
  padding: 20px 30px 30px 30px;
}

.info-item {
  display: flex;
  flex: 1;
}

.gold {
  display: inline-block;
  background-color: #f3e0b9;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  flex-basis: 70px;
  text-align: center;
}

.black {
  display: inline-block;
  background-color: #191d1d;
  color: #f3e0b9;
  padding: 0 5px;
  height: 30px;
  line-height: 30px;
  width: 100%;
  flex: 1;
}

.info-item2 {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.left {
  display: inline-block;
  background-color: #191d1d;
  color: #f3e0b9;
  height: 40px;
  line-height: 40px;
  padding: 0 5px;
  flex-basis: 70px;
}

.right {
  display: inline-block;
  background-color: #f3e0b9;
  height: 40px;
  padding: 0 5px;
  flex: 1;
  word-break: break-all;
  display: flex;
  align-items: center;
}

.info-card {
  background-color: #191d1d;
  border-radius: 5px;
  color: white;
  margin-bottom: 10px;
  padding: 10px;
  line-height: 2;
  word-break: break-all;
}

.logo {
  width: 20px;
  width: 20px;
  object-fit: contain;
  margin: 0 5px;
}

.collectionModal .ant-modal-body {
  background-color: #000 !important;
}
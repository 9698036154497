.voucher{
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.voucher .voucher-img{
  width: 18%;
  margin-top: 20px;
  margin-left: 1.66%;
}
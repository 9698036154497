.people-container{
    width: 30%;
    border: 1px solid black;
    position: absolute;
    top: 20%;
    left: 35%;
    z-index: 9;
    padding: 30px;
    background-color: #fff;
    max-height: 50vh;
    overflow-y: scroll;
}
.people-box{
    margin: 0 auto;
    text-align: left;
}
.people-title{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.phone{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0 10px;
}
.phone-title{
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
}
.people-input{
    width: 90%;
    height: 25px;
    background-color: white;
    border: 1px solid;
    outline: none;
    padding-left: 5px;
}
.people-list{
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.people-log{
    width: 50px;
    height: 50px;
    margin: 0 20px;
}
.people-info{
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.people-info p{
    width: 100%;
    text-align: left;
}

.people-button{
    width: 100%;
    height: 40px;
    background: #c31c32;
    text-align: center;
    color: white;
    margin: 30px auto 20px;
    line-height: 40px;
    cursor: pointer;
}

.close{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
  }
.add-people{
    width: 100%;

}
.add-people p{
    width: 100%;
    text-align: left;
    font-size: 16px;
}
.add-people-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0;
    
}
.add-people-box .label{
    width: 120px;
    font-size: 15px;
    text-align: left;
}
.add-people-box .input-value{
    flex: 1;
    text-align: left;
}
.add-people-box .input-value input{
    border: 1px solid #d9d9d9 ;
    outline: none;
    width: 100%;
    height: 30px;
    padding-left: 10px;
}
.ant-modal-content{
  max-height: 70vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.ant-modal-content::-webkit-scrollbar{
  display: none;
}
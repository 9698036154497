.container{
  margin-top: 55px;
  width: 100%;
}
.container1{
  height: 28px;
  width: 100%;
}
.club{
  --drop-down-color:rgb(250, 172, 3)
}
.club-content{
  width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: white;
  min-height: 80vh;
}
.club-tabbar{
  width: 100%;
  display: flex;
  height: 65px;
  justify-content: center;
  align-items: center;
}
.club-tabbar .title{
  flex: 1;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-color: #f5f5f5;
  border-right: 3px solid #efefef;
  border-bottom: 5px solid #efefef;
  box-sizing: border-box;
  color: black;
}
.club-tabbar .title:last-child{
  border-right: none;
}
.club-tabbar .title:hover{
  cursor: pointer;
  background-color:#fff ;
}
.club-tabbar .active{
  background-color:#fff ;
}
.content-message{
  width: 100%;
} 
.content-message img{
  width: 100%;
}
.Schedule .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  width: auto !important;
}
.ant-select-selection-overflow-item{
  min-width: 100px !important;
}
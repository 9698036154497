.con {
  overflow: hidden;
  --begin-let: 0px;
}

.div {
  display: flex;
  width: 100%;
  position: nowrap;
  --div-width:0;
}

.animation-center {
  animation: centerKey 1s forwards;
}

.animation-begin {
  animation: beginKey 1s forwards;
}

.animation-end {
  animation: endKey 1s forwards;
}

@keyframes centerKey {
  0% {
      transform:translateX( var(--begin-let)  ) ;
  }
  100% {
      transform: translateX( calc( 100%/2 - (var(--div-width) + 7*10px) / 2) );
  }
}

@keyframes beginKey {
  0% {
      transform: translateX( var(--begin-let)  ) ;
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes endKey {
  0% {
      transform: translateX( var(--begin-let)  ) ;
  }
  100% {
      transform: translateX( calc(100% - (var(--div-width) + 7*10px)) );
  }
}


.line {
  margin: 5px;
  width: 30%;
  min-width: 30%;
  height: 295px;
  border: 1px solid #efefef;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
.line:nth-child(4){
  width: 50%;
  min-width: 50%;
  font-size: 20px;
}



.line4 {
  width: 400px;
}

.header-item{
  width: 100%;
  height: 15%;
  padding-left: 1rem;
  margin:-0.1rem;
  display: flex;
  color: white;
  justify-content: left;
  align-items: center;
  background-color: #124584;
}
.header-item .data-day{
  font-size: 40px;
  margin-right: 10px;
  font-weight: 600;
  color:#fff;
}
.item-data{
  display: inline-block;
 word-spacing: normal;
}
.data-weekday{
  font-size: 13px;
  font-weight: 600;

}
.data-month{
  font-size: 12px;

}
.match{
  width: 100%;
  height: 53%;
  padding: 10px 0;
}
.match-top{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
}
.match-name{
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-img{
  width: 30px;
  height: 30px;
}
.match-turn{
  font-size: 13px;
  /* color: #d9d9d9; */
}
.match-team{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
.match-team .home-team{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.home-img{
  width: 50px;
  height: 50px;
}
.match-score{
  flex: 1;
  text-align: center;
}
.score{
  padding: 5px 8px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  background-color: #243464;
}
.clien-team{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.clien-img{
  width: 50px;
  height: 50px;
}
.team-info{
  width: 100%;
}
.team-info .team-name{
  font-size: 16px;
  font-weight: bold;
}
.match-address{
  font-size: 13px;
  color: #848484;
}
.bottom{
  height: 25%;
  width: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
  border-top: 1px solid #efefef;
}
.bottom span{
  display: block;
 width: 160px;
 height: 32px;
 line-height: 32px;
 font-size: 14px;
 color: black;
  background-color: #fdc52c;
}
.bottom span:hover{
  background-color: #cd122d;
  color: #fff;
}
.pointer-button{
  width: 100%;
  margin-top: 2rem;
  text-align: center;
}
.button-box{
  width: 15%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.button-box p{
  width: 32%;
  height: 5px;
  background-color: #d8d8d8;
}
.button-box p:hover{
  background-color: #cd122d;
}
.button-box .active{
  background-color: #cd122d;
}
.game-ticket-list{
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.game-ticket-list .ticket-list-one{
    width: 305px;
    height: 490px;
    margin: 10px 0.5% 0;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid rgb(156, 124, 64);
    background-color: #fff;
}
.game-ticket-list .ticket-list-one .ticket-logo{
    width: 100%;
    height: 300px;
}
.game-ticket-list .ticket-list-one .ticket-name{
    width: 95%;
    height: 50px;
    margin:5px auto;
    text-align: left;
    font-weight: bold;
    font-size: 15px;
    overflow: hidden;
}
.game-ticket-list .ticket-list-one .ticket-info{
  width: 95%;
  margin: 0 auto;
  padding: 20px 0 0;
  border-top: 2px dashed #848484 ;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}
.game-ticket-list .ticket-list-one .ticket-info .ticket-info-option{
  width: 50%;
  display: flex;
  align-items:center;
  justify-content: start;
  font-weight: bold;
  margin-bottom: 5px;
}
.game-ticket-list .ticket-list-one .ticket-button {
    width: 60%;
    margin: 10px auto;
    background-color: #fdc52c;
    font-size: 15px ;
    font-weight: 600;
    padding: 5px 0;
    cursor: pointer;
}

.purchase-box{
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
}
.purchase-box-left{
  width: 70%;
  max-height: 90vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-right: 2px solid #efefef;
}
.purchase-box-left::-webkit-scrollbar{
  display: none;
}
.purchase-box-right{
    width: 30%;
    max-height: 90vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.purchase-box-right::-webkit-scrollbar{
  display: none;
}
.purchase-right-title{
    font-size: 18px;
    font-weight: bold;
    margin: 30px;
}
.purchase-box-right .ticket-area{
    width: 80%;
    border: 1px solid black;
    margin: 40px auto;
    padding: 20px;
}
.purchase-box-right .ticket-area .area-title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 30px 0;
}
.purchase-box-right .ticket-area .area-title .title-left{
    width: 100px;
    height: 40px;
}

.purchase-box-right .ticket-area .area-title .title-right{
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    text-align: right;
}
.area-title .cart-num{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.area-title .cart-num p{
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin: 0 5px;
    background-color: #efefef;
    font-size: 26px;
    cursor: pointer;
}
.area-title .cart-num input{
    width: 30px;
    height: 30px;
    text-align: center;
    border: none;
    outline: none;
    background-color: #efefef;
}
.choose-seat{
 width: 100%;
}
.choose-seat .choose-seat-title{
    width: 90%;
    margin: 30px auto;
    font-size: 20px;
    font-weight: bold;
    position: relative;

}
.choose-seat .choose-seat-title .back-button{
    position: absolute;
    left: 0;
    top:-5px;
    color: #fff;
    z-index: 2;
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 600;
    background-color: #c31c32;
    cursor: pointer;
}
.choose-seat .svgBox svg {
    /* background-color: #f5f5f5; */
    overflow: hidden;
    height: auto !important;
    width: 120% !important;
  }
.choose-location{
    width: 100%;
    margin: 20px auto;
}
.location-title{
    text-align: left;
    font-size: 16px;
}
.location-list{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.location-name{
    flex: 1;
}
.add-name{
    flex: 1;
    font-weight: 600;
    color:blue;
    outline:blue ;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.next-ticket-button{
    width: 100%;
    color:blue;
    height: 40px;
    background: #f0cf64;
    text-align: center;
    margin: 30px auto 20px;
    line-height: 40px;
    cursor: pointer;
}
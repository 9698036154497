.enroll{
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.enroll-item {
  width: 21%;
  text-align: left;
  
  background-color: white;
  /* height: 170px; */
  padding: 5px 16px;
  position: relative;
  border-radius: 20px;
  margin: 10px 0;
  margin-left: 1.6%;
  border: 1px solid #b5b6b5;
}
.enroll-content {
  display: flex;
  align-items: center;
  position: relative;
  height: 100px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.enroll-item img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: top;
}

.enroll-middle {
  width: 75%;
  text-align: left;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.enroll-status {
  float: right;
  width: 100px;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
  border-radius: 20px;
  text-align: center;
}
.enroll:global .ant-modal-content{
  overflow: unset;
}

.option-main {
  padding: 20px;
  border: 1px dashed #000;
  margin: 20px;
  font-size: 16px;
}
.option-item {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}
.option-item a {
  width: 70px;
  height: 30px;
  color: white;
}
.btn {
  color: white;
  background-color: rgba(195, 28, 50, 1);
  position: fixed !important;
  width: 100%;
  bottom: 0;
  z-index: 1;
  font-size: 15px;
}

.quiz-item-detail {
  border-radius: 10px;
  /* box-shadow: 0px 1px 6px 0px #d0bfbf; */
  border: 1px solid white;
  margin: 10px 20px;
  padding: 5px;
  background-color: white;
}

.quiz-item-detail p {
  text-align: center;
}

.quiz-item:last-child {
  border-bottom: unset;
}

.quiz-item {
  padding: 20px 10px;
  border-bottom: 3px solid rgba(245, 245, 245, 100);
  background-color: white;
}

.quiz-item span {
  text-align: center;
  font-size: 14px;
}

.quiz-item p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.quiz-item img {
  align-self: center;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.quiz-info {
  flex-grow: 1;
}

.quiz-btn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #c31c32;
  border-radius: 20px;
  text-align: center;
  color: #c31c32;
}


.new_content{
  width: 95rem;
  margin:20px auto ;
  padding: 30px 20px;
  background-color: #fff;
}
.new_content .content-info{
  width: 100%;
}
.new_content .content-info .split-line{
  width: 100%;
  height: 10px;
  /* background-color: red; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.info-box{
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
.data-info{
  width: 15%;
  text-align: left;
  padding-top: 20px;
}
.data-info .data-day{
  font-size: 24px;
  font-weight: 600;
  color: black;
}
.data-week{
  font-size: 14px;
}
.new_content .see-more{
  width: 100%;
  text-align: center;
  margin: 40px 0;
}
.new_content .see-more .see-more-button{
  padding: 10px 30px;
  border-radius:30px;
  color: white;
  cursor: pointer;
}
.no-data-container {
  height: 30vh;
  width: 30%;
  margin:30vh auto 17vh;
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
}

.no-data-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.no-data-image{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.no-data-image.image {
  /* margin-top: auto; */
  margin-top: 15%;
  max-width: 100%;
  width: 100%;
  height: auto;
}
.no-data-text {
  position: relative;
  top: 80px;
  margin: 0;
  margin-top: auto;
  text-align: center;
  width: 100%;
  color: rgb(187, 182, 182);
}
.no-data-tip {
  position: absolute;
  top: 7%;
  left: 50%;
  font-size: 1.2rem;
  color: #ccc;
  transform: translateX(-50%);
}

.club-cards {
  width: 22%;
  height: 8vw;
  color: white;
  cursor: pointer;
  /* margin-left: 1%; */
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.button {
  position: absolute;
  right: 40px;
  top: 58px;
  z-index: 9;
}
.button p {
  color: #c31c32;
  background-color: white;
  padding: 5px 20px;
  border-radius: 50px;
}
.sign {
  font-weight: bold;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 9;
}

.integral-collection{
  width: 100%;
  text-align: left;
  padding-left: 20px;
}
.totalIntgral{
  font-size: 30px;
  color:blue;
}
.totalIntgral span{
  color: black;
  font-size: 30px;
  margin-left: 30px;
}
.availableIntgral{
  font-size: 30px;
  color:blue
}
.availableIntgral span{
  color: black;
  font-size: 20px;
  margin-left: 30px;
}
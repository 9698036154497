.purchase-box {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
}

.purchase-box-left {
    width: 70%;
    padding: 30px 25px;
    border-right: 2px solid #efefef;
}

.purchase-box-right {
    width: 30%;
}

.purchase-right-title {
    font-size: 18px;
    font-weight: bold;
    margin: 30px;
}

.item-box {
    width: 100%;
}

.item-box .item-box-titel {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
}

.item-box .item-box-titel span {
    font-size: 16px;
    font-weight: normal;
    margin-left: 20px;
}

.item-box-list {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: flex-start;
    align-content: center;
}

.item-box-list p {
    flex: 1;
    text-align: left;
}

.item-box .address {
    margin-bottom: 10px;
    text-align: left;
}

.item-box-list input {
    width: 300px;
    height: 30px;
    outline: none;
    text-align: center;
    border: 1px solid #919191;
}

.content-list {
    width: 90%;
    margin: 30px auto;
}

.content-list-one {
    width: 100%;
    /* height: 50px; */
    margin: 10px auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-list-one .content-img {
    width: 100px;
    height: 50px;
}

.content-list-one .content-info {
    /* flex: 1; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
}

.content-list-one .content-info span {
    width: 100%;
    font-size: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.content-list-one .content-price {
    font-size: 13px;
    font-weight: bold;
}

.sub-total {
    width: 100%;
    padding: 30px 0;
    border-top: 1.5px solid #efefef;
    border-bottom: 1.5px solid #efefef;
}

.sub-total-list {
    width: 90%;
    display: flex;
    margin: 10px auto;
}

.sub-total-list .total-title {
    flex: 1;
    font-size: 16px;
    text-align: left;
}

.sub-total-list .total-price {
    font-size: 14px;
    font-weight: bold;
}

.pay-now {
    width: 70%;
    background-color: #c31c32;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    margin: 50px auto 0;
    cursor: pointer;
}

.ant-checkbox-wrapper {
    align-items: center !important;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin: 0 !important;
}

.coupon-valid {
    height: 93px
}

.coupon-invalid {
    height: 93px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
}
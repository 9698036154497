.merchandise{
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    min-height: 80vh;
}
.left-detail{
    width: 70%;
    max-height: 90vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-right: 3px solid #efefef;
  }
.left-detail::-webkit-scrollbar{
  display: none;
}
.active-detail-message{
    width: 95%;
    margin: 10px auto 0;
}
.active-detail-message img{
    width: 100%;
}
.right-purchase{
  flex: 1;
  max-height: 90vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 30px 20px;
}
.right-purchase::-webkit-scrollbar{
  display: none;
}
.purchase-title{
    font-size: 20px;
    font-weight: 600;
}
.purchase-message{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: flex-start;
}
.message-img{
    width: 150px;
    height: 200px;
}
.message-text{
    flex: 1;
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
}
.message-text .goods-price{
    font-size: 13px;
    color: #c31c32;
}
.purchase-button{
    width: 100%;
}
.button-item{
    padding: 20px 0;
    border-bottom:1px solid #efefef ;
}
.button-item-title{
    font-size: 16px;
    margin: 10px 0;
    text-align: left;
}
.button-button{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
}
.button-button p{
    min-width:45px ;
    padding:10px;
    border: 2px solid #efefef;
    border-radius: 10px;
    font-size: 13px;
    margin: 2px;
}
.purchase-quantity{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 20px 0;
}
.purchase-quantity-title{
    flex: 1;
    font-size: 16px;
    text-align: left;
}
.puchase-quantity-number{
    display: flex;
    justify-content: left;
    align-items: center;
}
.puchase-quantity-number p{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    line-height: 35px;
    background-color: #f5f5f5;
    font-size: 30px;

}
.puchase-quantity-number input{
    height: 40px;
    width: 40px;
    margin: 0 10px;
    outline: none;
    border: none;
    text-align: center;
    background-color: #f5f5f5;
}
.puchase-quantity-number input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
  -webkit-appearance: none;
}
.puchase-quantity-number input[type='number']{
  -moz-appearance: textfield;
}
.purchase-bottom{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-cart{
    width: 120px;
    height: 40;
    text-align: center;
    line-height: 40px;
    background-color: #fdc52c;
    color:mediumblue;
    margin: 0 10px;
}
.buy-now{
    width: 120px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #c31c32;
    color: #fff;
    margin: 20px auto;
}
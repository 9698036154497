.container{
  /* margin-top: 35px; */
  width: 100%;
}
.activity{
  width: 85%;
  padding: 40px 0 0;
  margin: 0 auto;
  display: flex;
  position:relative;
  justify-content: center;
  background-color: white;
  min-height: 80vh;
}
.activity .activity-left-info{
  flex: 2;
  max-height: 90vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-right: 2px solid #efefef;
}
.activity .activity-left-info::-webkit-scrollbar{
  display: none;
}

.activity .activity-left-info .info-top{
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
.activity .activity-left-info .top-title{
  margin-bottom: 20px;
}
.activity .activity-left-info .info-bottom{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity .activity-left-info .info-bottom span{
  margin:0 10px;
}

.right-operation{
  flex: 1;
  max-height: 90vh;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.right-operation::-webkit-scrollbar{
  display: none;
}
.right-operation-title{
  width: 100%;
  margin: 30px auto 10px;
  font-size: 18px;
}
.input-number{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input{
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-operation .input{
  cursor: pointer;
}
.input input{
  height: 40px;
  width: 30%;
  margin: 0 20px;
  border: none;
  background: #ccc;
  text-align: center;
  outline: none;
}
.right-operation .line-up-list{
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  margin: 0 auto;
}
.right-operation .line-up-list .line-up-name{
  width: 31.4%;
  border-radius: 4.831vw;
  text-align: center;
  padding: 5px 0;
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  box-shadow: 0 0.483vw 1.449vw 0 #dad6d6;
  margin: 10px 0;
  margin-left: 1%;
  cursor: pointer;
}

.close{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.active-detail-message p{

}
.active-detail-message img{
  width: 100%;
}
.active-detail-message video{
  width: 100%;
}
.quiz-button{
  width: 40%;
  margin: 20px auto;
  background: #fdc52c;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: darkblue;
  cursor: pointer;
}

/* 投票 */
.vote{
  width: 70%;
  margin: 30px auto;
  border: 1px solid #efefef;
  border-radius: 5px;
}
.vote-title{
  padding: 20px 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.vote-option{
  padding:10px 20px ;
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vote-option .option-img{
  width: 50px;
  height: 50px;
}
.vote-option .option-message{
  flex: 1;
  font-size: 14px;
  padding: 0 10px;
  text-align: left;
}
.vote-option .option-radio{

}
.signup-option{
  width: 100%;
  height: 50px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #efefef;
}
.signup-option .name-option{
  flex: 1;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  border-right: solid 1px #efefef;
  cursor: pointer;
}
.signup-info{
  padding:0 20px;
}
.signup-info .info-title{
  font-size: 16px;
  text-align: left;
  margin: 20px 0;
  font-weight: bold;
}
.signup-info .info-option{
  display: flex;
  justify-content: left;
  align-content: center;
  margin: 10px 0;
  font-size:14px;
}
.signup-info .info-option .option-name{
  flex: 1;
  text-align: left;
  line-height: 30px;
}
.option-choose-button{
  width: 70px;
  height: 30px;
  color: white;
  background-color: #c31c32;
  text-align: center;
  line-height: 30px;
}
.info-option1{
  margin: 10px 0;
  font-size:14px;
}
.info-option1 .option-name{
  text-align: left;
}

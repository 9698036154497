.login-container {
  width: 30%;
  border: 1px solid black;
  position: absolute;
  top: 10vh;
  left: 35%;
  z-index: 9;
  padding: 30px;
  background-color: #fff;
}

.login-box {
  width: 250px;
  margin: 0 auto;
  text-align: left;
}

.login-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-top: 20px;
}

.phone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0 10px;
}

.phone-title {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
}

.login-input {
  width: 90%;
  height: 25px;
  background-color: white;
  border: 1px solid;
  outline: none;
  padding-left: 5px;
}

.prompt-information {
  font-size: 13px;
  color: #999999;
  text-align: left;
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  height: 40px;
  background: #c31c32;
  color: #fff;
  text-align: center;
  margin: 30px auto 20px;
  line-height: 40px;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.completed {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #1890ff;
}

.register-box {
  width: 90%;
  margin: 0 auto;
}

.register-button {
  width: 100%;
  height: 40px;
  background: #c31c32;
  text-align: center;
  margin: 30px auto 20px;
  line-height: 40px;
  cursor: pointer;
}

.realName-container {
  width: 30%;
  border: 1px solid black;
  position: absolute;
  top: 20%;
  left: 35%;
  z-index: 9;
  padding: 30px;
  background-color: #fff;
  max-height: 50vh;
  overflow-y: scroll;
}

.realName-box {
  margin: 0 auto;
  text-align: left;
}

.realName-title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.phone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px 0 10px;
}

.phone-title {
  font-size: 14px;
  font-weight: bold;
  margin: 10px 0;
}

.realName-input {
  width: 90%;
  height: 25px;
  background-color: white;
  border: 1px solid;
  outline: none;
  padding-left: 5px;
}

.realName-list {
  width: 100%;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.realName-log {
  width: 50px;
  height: 50px;
  margin: 0 20px;
}

.realName-info {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.realName-info p {
  width: 100%;
  text-align: left;
}

.realName-button {
  width: 100%;
  height: 40px;
  background: #027db3;
  text-align: center;
  margin: 30px auto 20px;
  line-height: 40px;
  cursor: pointer;
  color: white;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.add-realName {
  width: 100%;

}

.add-realName p {
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.add-realName-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 15px 0;

}

.add-realName-box .label {
  width: 120px;
  font-size: 15px;
  text-align: left;
}

.add-realName-box .input-value {
  flex: 1;
  text-align: left;
}

.add-realName-box .input-value input {
  border: 1px solid #d9d9d9;
  outline: none;
  width: 100%;
  height: 30px;
  padding-left: 10px;
}

.register-code {
  position: absolute;
  font-size: 14px;
  top: 0;
  right: -80px;
  z-index: 99;
  background: #fdc52c;
  color: #0000dc;
  width: 70px;
  line-height: 33px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}
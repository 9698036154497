.container{
  margin-top: 55px;
}
.container1{
  height: 28px;
  width: 100%;
}
.container2{
  margin-top: 35px;
}
/* @media (min-width:840px) {
  .container{
    margin-top: 35px;
  }
}

@media (max-width:841px) {
  .container{
    margin-top: 84px;
  }
} */
.new-detail{
  width: 70%;
  margin: 0 auto;
  background-color: white;
  min-height: 80vh;
  padding: 0 10px;
  position: relative;
}
.new-detail .title{
  color: black;
  text-align: left;
  font-weight: bold;
  margin: 10px 0 20px;
  font-size: 18px;
}
.new-detail .new-info{
  width: 99%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.new-detail .new-info .date{
  font-size: 13px;
}
.new-detail .new-info .info-right{
  display: flex;
  font-size: 13px;
}
.new-detail .new-info .info-right p{
  margin: 0 5px;
  font-size: 13px;
}
.close{
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.new-detail-message{
  padding-bottom: 20px;
}
.new-detail-message img{
  width: 100%;
}
.new-detail-message video{
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 500px) {

  html,
  body {
    width: 500px;
  }
}

* {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: content-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

img {
  vertical-align: unset;
  border-style: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0;
}
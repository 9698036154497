.payment_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
}

.payment_form {
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
}
.payment_form-cell {
  height: 55px;
}

.payment_card {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
}

.payment_footer {
  margin-top: auto;
  height: 50px;
  background-color: rgba(195, 28, 50, 1);
  color: white;
  font-size: 14px;
  text-align: center;
  border-radius: 0;
  font-weight: bold;
}

.payment_money {
  color: black;
  font-size: 34px;
  font-weight: bold;
}
.payment_money:before {
  content: '￥';
  font-size: 12px;
  font-weight: 400;
}

.payment_info {
  font-size: 12px;
  color: #999;
}

.container{
  margin-top: 55px;
  width: 100%;
}
.ticket-top {
  padding-top: 1.6rem;
  position: relative;
  text-align: center;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
}
.ticket-top__competition-logo{
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content:center;
}
.ticket-date{
  margin: 0.4rem 0 0.8rem;
  font-size: 21px;
  line-height: 3.8rem;
  font-weight: bold;
  text-transform: uppercase;
}
.ticket_link{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 90%;
  margin: 0 auto;
}
.ticket-team{
  flex: 4;
  display: flex;
  align-items: center;
}
.ticket-home-team{
  justify-content: flex-end;
}
.ticket_link .team-name{
  font-size: 26px;
  margin: 0 20px;
  font-weight: bold;
}
.ticket_link .team-logo{
  width: 114px;
  height: 114px;
  margin: 0 30px;
}
.ticket-team-content{
  flex: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  height: 114px;
}
.match-begin{
  font-size: 13px;
}
.match-time{
  font-size: 40px;
  
}
.match-adress{
  font-size: 14px;
}

.countdown {
  position: relative;
  display: flex;
  max-width: 52rem;
  height: 8rem;
  margin: 0 auto;
  padding: 1.2rem 1.6rem;
  border-radius: 0.5rem;
  /* background-image: url(../i/bg-elements/stripes-horizontal.png); */
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  /* color: #fff; */
}
.countdown__content--no-sponsor {
  align-items: center;
  display: flex;
}
.countdown__content {
  /* flex: 1; */
  text-align: right;
}
.fixture-hero .countdown__title-container {
  align-items: flex-start;
}
.countdown__title-container {
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-family: fcb-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.countdown__title {
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 0.3rem;
  font-size: 14px;
}
.countdown__clock {
  display: flex;
  /* flex: 1; */
  align-items: center;
  /* color: #fff; */
}
.countdown__count {
    display: flex;
    align-items: center;
    font-size: 44px;
}
.countdown__clock-item.is-zero .countdown__value {
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.countdown__value {
  min-width: 5.2rem;
  line-height: 4.7rem;
  font-family: fcb-extra-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.countdown__separator {
  display: inline-block;
  margin: 0 0.2rem;
  line-height: 4.8rem;
  font-family: fcb-light,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: 400;
}
.countdown__count-label1 {
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.2rem;
  /* color: #fdc52c; */
  font-family: fcb-bold,Arial,Helvetica Neue,Helvetica,sans-serif;
  font-weight: bold;
}


.ticket-top-ticketing{
  width: 90%;
  margin: 0 auto;
  padding: 2.4rem 0;
  border-top: 0.1rem solid hsla(0,0%,100%,.3);
  padding-left: 12px;
}
.ticket-cta{
  /* max-width: 14.84rem; */
  padding:0.4rem 0;
  width: 10%;
  margin: 0 auto;
  background-color: #fdc52c;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 5px;
  
}
.ticket-cta:hover{
  background-color: #cd122d;
  cursor: pointer;
}

.ticket-list{
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 25px;
  padding: 20px 0;
  min-height: 40vh;
}
.ticket-list .ticket-list-content{
  width: 90%;
  margin: 0 auto;
}
.split-line{
  width: 100%;
  height: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.content-date{
  font-size: 32px;
  text-align: left;
  padding: 20px 0;
  font-weight: bold;
}
.ticket-list-ul{
  width: 98%;
   margin: 0 auto;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #efefef;
  /* padding-bottom: 20px; */
  padding: 20px 0;
}
.ticket-list-ul li{
  min-height: 40px;
}
.ticket-list-li-data{
  flex: 2;
  text-align: left;
  border-right: 1px solid #efefef;
}
.ticket-list-li-data .time{
  font-size: 15px;
  font-weight: bold;
}
.ticket-list-li-match{
  flex: 2;
  display: flex;
  padding: 0 10px;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  border-right: 1px solid #efefef;
}
.match-img{
  width: 32px;
  height: 32px;
}
.ticket-list-li-turn{
  flex: 1;
  text-align: center;
  font-size: 14px;
}
.ticket-list-li-team{
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-team{
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cline-team{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ticket-list-li-team .team-name{
  font-size: 20px;
  font-weight: bold;
}
.team-img{
  width: 44px;
  height: 44px;
  margin: 0 10px;
}
.VS{
  font-size: 16px;
  padding: 0 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ticket-list-li-button{
 flex: 3;
 display: flex;
 justify-content: center;
 align-items: center;
}
.ticket-list-li-button .ticket-button{
  width: 50%;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}
.ticket-list-li-button .ticket-button:hover{
  cursor: pointer;
  background-color: #cd122d;
}